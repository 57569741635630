@import "variables";
@import "mixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;
  padding: 14px 50px;
  box-sizing: border-box;
  background-color: $endeavour;

  @include respond-to(fablet) {
    margin-top: 60px;
    padding: 20px;
  }

  @include respond-to(phone) {
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .social {

    .text {
      color: $white;
    }
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;

      @include respond-to(phone) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
