@import "variables";
@import "mixins";

.slider {
  position: relative;
  z-index: 5;
  width: 970px;
  max-width: 100%;
  margin: 60px auto 110px;

  @include respond-to(fablet) {
    margin-bottom: 90px;
  }

  .slick-dots {
    @include nl();
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    li {

      &:not(:first-child) {
        margin-left: 8px;
      }

      &.slick-active {

        button {
          background-color: $blue;
        }
      }
    }

    button {
      display: block;
      width: 14px;
      height: 14px;
      border: 2px solid $blue;
      border-radius: 50%;
      font-size: 0;
      background-color: transparent;
      cursor: pointer;
      transition: $basicTransition;
    }
  }

  &__item {
    display: block !important;
    height: 647px;

      @include respond-to(tablet) {
        position: relative;
        height: 0;
        padding-top: 67%;
      }

      img {

        @include respond-to(tablet) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
  }
}
