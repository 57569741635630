@import "variables";
@import "mixins";

.list {
  @include nl();
  margin-top: 20px;
  font-size: 1.6rem;
  line-height: 150%;

  li {

    &:not(:first-child) {
      margin-top: 10px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 6px;
      border-radius: 50%;
      background-color: $blue;
    }

    strong {
      font-size: 2.4rem;
      line-height: 150%;
      font-weight: bold;

      @include respond-to(fablet) {
        font-size: 2rem;
      }
    }
  }

  &--counter {
    counter-reset: count;
    margin-top: 30px;

    li {

      &::before {
        counter-increment: count;
        content: counter(count)'.';
        width: auto;
        height: auto;
        border-radius: initial;
        font-size: 2.4rem;
        line-height: 150%;
        font-weight: bold;
        color: $blue;
        background-color: initial;
      }
    }
  }
}

