/* Your custom fonts here */

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: 
      url("../fonts/Montserrat/Montserrat-Light.woff2") format("woff2"), 
      url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"),
      url("../fonts/Montserrat/Montserrat-Light.ttf") format("ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: 
      url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"), 
      url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
      url("../fonts/Montserrat/Montserrat-Regular.ttf") format("ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: 
      url("../fonts/Montserrat/Montserrat-Italic.woff2") format("woff2"), 
      url("../fonts/Montserrat/Montserrat-Italic.woff") format("woff"),
      url("../fonts/Montserrat/Montserrat-Italic.ttf") format("ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: 
      url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: 
      url("../fonts/Montserrat/Montserrat-Bold.ttf") format("ttf");
}

// Thin: 100
// ExtraLight: 200
// Light: 300
// Regular: 400
// Medium: 500
// SemiBold: 600
// Bold: 700
// ExtraBold: 800
// Black: 900
