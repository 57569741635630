@import "variables";
@import "mixins";

.social {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: 1px solid $white;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: $white;
    box-shadow: $basicShadow;
    transition: $basicTransition;

    &:hover {
      background-color: transparent;

      path {
        fill: $white;
      }
    }

    &:not(:first-child) {
      margin-left: 16px;

      @include respond-to(fablet) {
        margin-left: 9px;
      }
    }

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    path {
      transition: $basicTransition;
    }
  }

  &-share {

    .social__item {
      cursor: pointer;
    }
  }

  &--blue {

    .social {

      &__item {
        border-color: $blue;
        background-color: $blue;

        &:hover {
          background-color: transparent;
    
          path {
            fill: $blue;
          }
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &--row {
    flex-direction: column;

    .social {

      &__list {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }
  }
}
