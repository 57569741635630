@import "variables";
@import "mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 50px;
  box-sizing: border-box;

  @include respond-to(fablet) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;
    }
  }

  .logo {
    display: none;

    @include respond-to(laptop) {
      display: block;
    }

    @include respond-to(phone) {
      width: 100px;
      height: 74px;
    }

    &--light {
      display: block;

      @include respond-to(laptop) {
        display: none;
      }
    }
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;

    @include respond-to(laptop) {
      background-color: $white;
    }
  }
}