@import "variables";
@import "mixins";

html {
  // height: 100%;
  min-height: 100%;
  font-size: $basicFontSize;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
ol,
ul,
input,
button,
textarea,
blockquote {
  padding: 0;
  margin: 0;
}

body {
  max-width: 2560px;
  margin: 0 auto;
  height: 100%;
  font-family: $montserrat;
  color: $mineShaft;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  &.inactive {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  transition: $basicTransition;

  &:link {
    text-decoration: none;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;

  &.cover {
    height: 100%;
    object-fit: cover;
  }

  &.contain {
    height: 100%;
    object-fit: contain;
  }
}

button,
input,
textarea,
select {
  border: none;
  background: none;
  outline: none;
}

button {
  cursor: pointer;

  &.disable {
    pointer-events: none;
  }
}

.title {
  font-size: 4.8rem;
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;

  @include respond-to(fablet) {
    font-size: 3rem;
  }

  @include respond-to(phone) {
    word-break: break-word;
  }

  &--xxl {
    font-size: 9.6rem;
    line-height: 100%;
  }

  &--md {
    font-size: 4.5rem;
    line-height: 100%;
  }

  &--xs {
    font-size: 3.6rem;

    @include respond-to(fablet) {
      font-size: 2.4rem;
    }
  }
}

.text {
  font-size: 1.6rem;
  line-height: 150%;

  &--xl {
    font-size: 2.4rem;
  }

  &--md {
    font-size: 2.1rem;
    line-height: 120%;
  }

  &--xs {
    font-size: 1.4rem;
    line-height: 135%;
  }

  &--bold {
    font-weight: 600;
  }

  &--light {
    font-weight: 300;
  }

  &--italic {
    font-style: italic;
  }

  &--center {
    text-align: center;
  }
}

// .content {
//   overflow-x: hidden;
// }

.page {

  &-main {

    .container {

      .title {

        & + .text {
          margin-top: 40px;
        }
      }

      & > .text {
        margin-top: 20px;

        @include respond-to(fablet) {
          margin-top: 20px;
        }

        &:first-child {
          margin-top: 0;
        }
    
        a {
          color: $blue;
    
          &:hover {
            text-decoration: underline;
          }
        }
    
        strong {
          font-weight: 600;
        }
      }

      .blockquote-border {

        & + .text {
          margin-top: 60px;
        }
      }
    }
  }

  &-quiz {

    .header {

      .logo {

        @include respond-to(desktop) {
          width: 110px;
          height: 80px;
        }
      }
    }
  }
}

.container {
  position: relative;
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  @include respond-to(laptop) {
    padding: 0 65px;
  }

  @include respond-to(fablet) {
    padding: 0 14px;
  }

  &--xs {
    width: 770px;

    @include respond-to(tablet) {
      padding: 0 65px;
    }

    @include respond-to(fablet) {
      padding: 0 15px;
    }
  }
}

.special-link {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: $white;
  background-color: $blue;
  transition: $basicTransition;

  &:hover {
    background-color: #084589;
  }

  &.mobile {
    display: none;

    @include respond-to(tablet) {
      display: flex;
      width: 300px;
      max-width: 100%;
      margin: 30px auto 0;
      padding: 6px;
      border-radius: 177px;
    }

    @include respond-to(tablet) {
      margin-top: 60px;
    }

    & + .section {

      @include respond-to(tablet) {
        padding-top: 60px;
      }
    }
  }

  &__img {
    flex-shrink: 0;
    width: 89px;
    height: 89px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__title {
    margin-left: 15px;
    font-size: 1.4rem;
    line-height: 140%;

    strong {
      font-weight: 600;
    }
  }

  &--fixed {
    position: fixed;
    top: 90px;
    right: 0;
    z-index: 300;
    border: 1px solid $white;
    padding: 6px;
    padding-right: 70px;
    border-top-left-radius: 177px;
    border-bottom-left-radius: 177px;
    opacity: 0;
    visibility: hidden;

    @include respond-to(tablet) {
      z-index: -1;
      display: none;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}
