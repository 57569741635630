@import "variables";
@import "mixins";

.logo {
  display: block;
  width: 140px;
  height: 103px;

  &--small {
    width: 98px;
    height: 72px;
  }
}
