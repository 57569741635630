@import "variables";
@import "mixins";

.video {
  width: 1124px;
  max-width: 100%;
  margin: 60px auto 0;

  &__content {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
  }

  &__poster,
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
  }

  .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
