@import "variables";
@import "mixins";

.blockquote {
  width: 568px;
  max-width: 100%;

  @include respond-to(laptop) {
    width: 438px;
  }

  @include respond-to(small_tablet) {
    width: 100%;
    margin-top: 60px;
  }

  &::before {
    content: '';
    display: block;
    width: 100px;
    height: 65px;
    margin-bottom: 40px;
    background-image: url(../img/general/icon/icon_quotes_white.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &-border {
    position: relative;
    margin-top: 120px;
    padding: 70px 0 40px;
    border-top: 8px solid $blue;
    border-bottom: 8px solid $blue;

    @include respond-to(fablet) {
      margin-top: 90px;
      padding-top: 45px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -67px;
      right: 0;
      width: 170px;
      height: 95px;
      background-image: url(../img/general/icon/icon_quotes.svg);
      background-position: center right;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: $white;

      @include respond-to(fablet) {
        top: -32px;
        width: 80px;
        height: 46px;
      }
    }

    cite {
      display: block;
      margin-top: 20px;
      font-size: 1.6rem;
      line-height: 120%;
      font-style: italic;
    }

    &--person {
      padding: 30px 0;
    }
  }

  &-question {

    &__title {
      font-size: 2.4rem;
      line-height: 150%;
      font-weight: bold;
    }

    cite {
      margin-top: 30px;
    }

    .text {
      margin-top: 20px;
    }
  }

  &--white {
    color: $white;
  }
}
