@import "variables";
@import "mixins";

.btn {
  display: inline-block;
  padding: 18px 40px 17px;
  border: 1px solid $white;
  border-radius: 40px;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 120%;
  text-align: center;
  color: $blue;
  background-color: $white;
  box-shadow: $basicShadow;
  transition: $basicTransition;

  @include respond-to(phone) {
    width: 100%;
  }

  &:hover {
    color: $white;
    background-color: transparent;
  }

  &-down {
    width: 60px;
    height: 60px;
    box-shadow: $basicShadow;
    animation: spin 1.3s infinite linear;
  }

  &-play {
    width: 100px;
    height: 100px;

    @include respond-to(small_tablet) {
      width: 60px;
      height: 60px;
    }

    svg {
      margin-left: 9px;

      @include respond-to(small_tablet) {
        width: 18px;
        height: 24px;
        margin-left: 5px;
      }
    }
  }

  &-home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $blue;
    border-radius: 50%;
    background-color: $blue;
    box-shadow: $basicShadow;
    transition: $basicTransition;

    &:hover {
      background-color: transparent;

      path {
        fill: $blue;
      }
    }

    path {
      transition: $basicTransition;
    }
  }

  &-down,
  &-play {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $white;
    border-radius: 50%;
    background-color: $white;
    transition: $basicTransition;

    &:hover {
      background-color: transparent;

      path {
        fill: $white;
      }
    }

    path {
      transition: $basicTransition;
    }
  }

  &-arrow {
    position: absolute;
    top: 50%;
    display: block;
    width: 60px;
    height: 100%;
    background-image: url(../img/general/icon/icon_arrow.svg);
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: transparent;
    transition: $basicTransition;
    cursor: pointer;

    @include respond-to(small_tablet) {
      display: none;
    }

    &:hover {
      background-color: rgba($blue, 0.5);
    }

    &--prev {
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }

    &--next {
      right: 0;
      transform: translateY(-50%);
    }
  }

  &--blue {
    border-color: $blue;
    color: $white;
    background-color: $blue;

    &:hover {
      color: $blue;
      background-color: transparent;
    }
  }

  &--md {
    padding-left: 65px;
    padding-right: 65px;

    @include respond-to(phone) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

@keyframes spin {
  0% {
    transform: translate3d(0, -8px, 0);
  }
  50% {
    transform: translate3d(0, 8px, 0);
  }
  100% {
    transform: translate3d(0, -8px, 0);
  }
}
