@import "variables";
@import "mixins";

.person {

  &__header {
    display: flex;
    align-items: center;

    @include respond-to(fablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {

      &:first-child {
        flex-shrink: 0;
      }

      &:not(:first-child) {
        margin-left: 30px;

        @include respond-to(fablet) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }

  &__img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__name {
    line-height: 120%;
  }

  &__position {
    margin-top: 15px;
  }

  &__content {
    margin-top: 30px;
  }
}
