@import "variables";
@import "mixins";

.quiz {

  .btn {
    margin-top: 40px;

    @include respond-to(desktop) {
      margin-top: 30px;
    }
  }

  &__intro,
  &__content,
  &__result {
    margin-top: 60px;

    @include respond-to(desktop) {
      margin-top: 35px;
    }
  }

  &__header {
    text-align: center;

    .title {
      margin-top: 15px;
      font-size: 4.2rem;
      line-height: 100%;

      @include respond-to(desktop) {
        font-size: 3.6rem
      }

      @include respond-to(fablet) {
        font-size: 3rem
      }
    }
  }

  &__intro {
    text-align: center;
  }

  &__content {
    display: none;
  }

  &__result {
    display: none;
  }

  &__share {
    margin-top: 70px;

    @include respond-to(desktop) {
      margin-top: 40px;
    }

    .social {
      justify-content: center;
      margin-top: 20px;
    }
  }
}

.question {
  display: flex;
  justify-content: center;

  @include respond-to(laptop) {
    flex-direction: column-reverse;
    justify-content: initial;
    align-items: center;
  }

  &__item {

    &:first-child {
      flex-grow: 1;
      width: 100%;
      max-width: 520px;
      padding-top: 75px;

      @include respond-to(desktop) {
        padding-top: 0;
      }

      @include respond-to(laptop) {
        margin-top: 60px;
      }

      @include respond-to(fablet) {
        margin-top: 40px;
      }
    }

    &:not(:first-child) {
      margin-left: 80px;

      @include respond-to(laptop) {
        margin-left: 0;
      }
    }
  }

  &__img {
    width: 570px;
    max-width: 100%;
    height: 570px;
    border-radius: 50%;
    overflow: hidden;

    @include respond-to(desktop) {
      width: 400px;
      height: 400px;
    }

    @include respond-to(phone) {
      width: 288px;
      height: 288px;
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 150%;

    @include respond-to(desktop) {
      font-size: 1.6rem;
    }
  }

  &__desc {
    display: none;
    margin-top: 40px;
    font-weight: 300;
    font-style: italic;
  }
}

.answers {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @include respond-to(desktop) {
    margin-top: 25px;
  }

  &__item {
    padding: 20px 30px 19px;
    border: 1px solid $blue;
    border-radius: 40px;
    font-size: 1.6rem;
    line-height: 120%;
    color: $blue;
    cursor: pointer;
    transition: $basicTransition;

    @include respond-to(fablet) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:hover {
      color: $white;
      background-color: $blue;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.result {
  text-align: center;

  &__num {

    @include respond-to(desktop) {
      font-size: 6.4rem;
    }
  }

  &__text {
    margin-top: 50px;
    font-size: 2.4rem;
    line-height: 150%;

    @include respond-to(desktop) {
      margin-top: 30px;
      font-size: 2rem;
    }

    @include respond-to(fablet) {
      font-size: 1.8rem;
    }
  }
}
