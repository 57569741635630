@import "variables";
@import "mixins";

.teaser {
  position: relative;
  padding: 50px 65px;
  box-sizing: border-box;
  background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 46.1%, rgba(255, 255, 255, 0.8) 100%), url(../img/content/bg/img_001.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $mineShaft;

  @include respond-to(laptop) {
    padding-right: 0;
    padding-left: 0;
  }

  @include respond-to(small_tablet) {
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%), url(../img/content/bg/img_007.jpg);
    background-position: top center;
  }

  @include respond-to(fablet) {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  @include respond-to(small_phone) {
    background-image: url(../img/content/bg/img_008.jpg);
  }

  &__content {
    width: 570px;
    max-width: 100%;

    @include respond-to(tablet) {
      width: 460px;
    }
  }

  &__title {
    margin-top: 50px;
    color: $blue;

    @include respond-to(phone) {
      margin-top: 170px;
      font-size: 3rem;
    }
  }

  .container {
    position: static;
  }

  .btn {

    &-list {
      display: flex;
      flex-wrap: wrap;
      width: 420px;
      max-width: 100%;
      margin-top: 50px;

      @include respond-to(phone) {
        width: 100%;
        margin-top: 30px;
      }
  
      .btn {
        
        &:not(:last-child) {
          width: calc(50% - 10px);

          @include respond-to(phone) {
            width: 100%;
          }
        }
  
        &:nth-child(2) {
          margin-left: 20px;

          @include respond-to(phone) {
            margin-top: 20px;
            margin-left: 0;
          }
        }
  
        &:last-child {
          flex-grow: 1;
          margin-top: 20px;
        }
      }
    }

    &-down {
      margin: 90px auto 0;

      @include respond-to(tablet) {
        margin-top: 220px;
      }

      @include respond-to(small_tablet) {
        margin-top: 120px;
      }

      @include respond-to(phone) {
        margin-top: 60px;
      }
    }
  }

  .social {
    position: absolute;
    top: 50px;
    right: 50px;

    @include respond-to(small_tablet) {
      position: relative;
      top: auto;
      right: auto;
      width: 420px;
      max-width: 100%;
      margin-top: 20px;
    }

    .text {
      color: $blue;
    }
  }
}

.intro {
  text-align: center;

  .social {
    margin-top: 20px;
    justify-content: center;
  }

  .text {

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__share,
  &__text {
    margin-top: 40px;
  }

  &__text {

    strong {
      font-weight: 600;
    }
  }
}

.product {
  display: flex;
  align-items: flex-end;

  @include respond-to(small_tablet) {
    flex-direction: column-reverse;
    align-items: initial;
  }

  .btn {
    margin-top: 30px;
  }

  &__item {

    &:first-child {
      flex-shrink: 0;
      width: 424px;
      padding-bottom: 70px;

      @include respond-to(laptop) {
        width: 305px;
      }

      @include respond-to(small_tablet) {
        width: 100%;
        margin-top: 35px;
        padding-bottom: 0;
      }
    }

    &:last-child {
      position: relative;
      left: -40px;

      @include respond-to(small_tablet) {
        left: auto;
      }
    }
  }

  &__title {

    span {
      text-transform: uppercase;
    }
  }

  &__img {
    width: 499px;
    max-width: 100%;
    height: 499px;

    @include respond-to(small_tablet) {
      width: 300px;
      height: 300px;
      margin: 0 auto;
    }
  }
}

.section {
  position: relative;
  padding-top: 120px;

  @include respond-to(fablet) {
    padding-top: 60px;
  }

  &--bg {
    color: $white;
    background-size: auto;
    background-repeat: no-repeat;

    @include respond-to(laptop) {
      background-size: cover;
    }
  }

  &--intro {
    padding-top: 130px;
    padding-bottom: 120px;
    background-image: url(../img/content/bg/img_002.svg);
    background-position: top center;
    
    @include respond-to(fablet) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &--quote {
    margin-top: 60px;
    padding-top: 195px;
    padding-bottom: 200px;
    background-image: url(../img/content/bg/img_003.svg);
    background-position: bottom center;
    overflow: hidden;

    @include respond-to(small_tablet) {
      padding-top: 0;
      padding-bottom: 95px;
    }

    @include respond-to(fablet) {
      padding-bottom: 60px;
    }

    .section__img {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(100% - 175px);
      transform: translateY(-50%);
      width: 565px;
      height: 521px;

      @include respond-to(laptop) {
        left: calc(100% - 245px);
      }

      @include respond-to(small_tablet) {
        position: relative;
        left: auto;
        bottom: auto;
        transform: none;
        width: 288px;
        height: 266px;
      }
    }
  }

  &--list {
    position: relative;
    margin-top: 80px;
    padding: 260px 0 90px;
    background-image: url(../img/content/bg/img_004.svg);
    background-position: top center;

    @include respond-to(small_tablet) {
      margin-top: 0;
      padding-top: 210px;
      padding-bottom: 60px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      width: 100%;
      max-width: 1920px;
      height: calc(100% / 3);
      background-color: $endeavour;

      @include respond-to(small_tablet) {
        display: none;
      }
    }

    .list {
      margin-top: 30px;

      li {

        &:before {
          background-color: $white;
        }
      }
    }
  }

  &--product {
    padding-top: 100px;
    padding-bottom: 90px;
    background-image: url(../img/content/bg/img_005.svg);
    background-position: bottom center;
    overflow: hidden;

    @include respond-to(small_tablet) {
      padding-top: 90px;
    }

    @include respond-to(fablet) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &--quiz {
    min-height: 100vh;
    padding-top: 160px;
    padding-bottom: 60px;
    box-sizing: border-box;
    background-image: url(../img/content/bg/img_006.svg);
    background-attachment: fixed;
    background-position:top center;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @include respond-to(desktop) {
      padding-top: 125px;
      padding-bottom: 80px;
    }

    @include respond-to(laptop) {
      background-image: none;
    }
  }
}
